import { FC } from 'react';
import { DialogTextField, DialogTextFieldProps } from '../Dialog/GenericDialog';
import { TextFieldMultilineWrapper } from './MultiLineTextfield.css';

export const MultiLineTextField: FC<DialogTextFieldProps> = ({ rows, minRows, maxRows, ...rest }) => {
    return (
        <TextFieldMultilineWrapper>
            <DialogTextField multiline minRows={minRows} maxRows={maxRows} rows={rows} {...rest} />
        </TextFieldMultilineWrapper>
    );
};
