import { Project } from './Project';
import { Tenant } from './Tenant';
import { Module } from './Module';
import { DeviceClasses, PageStyle } from './PageStyle';
import { PUBLISHED_STATUS } from '../utils/fnPublish';
import { PlacedObjectType } from './Object';

export type Page = {
    _id: string;
    name: string;
    intent: string;
    options?: any;
    tenantId: string;
    projectId: string;
    tenant?: Tenant;
    project?: Project;
    pageStyleId?: string;
    conditionIds?: string[];
    moduleIds?: string[];
    conditions?: Condition[];
    modules?: Module[];
    lastModified?: number;
    modifiedBy?: string;
    modifiedByUser?: {
        name: string;
        icon?: string;
    };
    pageStyle?: PageStyle;
    published?: boolean;
    publishAt?: number;
    publishStatus?: PUBLISHED_STATUS;
    increasedTopMargin?: boolean;
    backgroundImage?: DeviceClasses;
    abTestingGroupIds?: string[];
    slug?: string;
    canonical?: string;
    description?: string;
    placed?: { name: string; _id: string; type: PlacedObjectType }[];
    locked?: string;
    lockedByUser?: {
        name: string;
        icon?: string;
    };
    adminLocked?: boolean;
    filteredModules?: Module[];
};
//used for now until the structure of page conditioning object will be known
type Condition = {
    name: string;
};

export const PageIntents = {
    BASIC: 'BASIC',
    HOME: 'HOME',
    EPG: 'EPG',
    WELCOME: 'WELCOME',
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP',
    LANDING: 'LANDING',
    SETTINGS: 'SETTINGS',
    RECORDINGS: 'RECORDINGS',
    FAVOURITES: 'FAVOURITES',
    APPS: 'APPS',
    SEARCH_LANDING: 'SEARCH_LANDING',
    SEARCH: 'SEARCH',
    SEARCH_NO_RESULTS: 'SEARCH_NO_RESULTS',
    AUTO_COLLECTION: 'AUTO_COLLECTION',
    TV_SERIES_DETAILS: 'TV_SERIES_DETAILS',
    TV_MOVIE_DETAILS: 'TV_MOVIE_DETAILS',
    VOD_SERIES_DETAILS: 'VOD_SERIES_DETAILS',
    VOD_MOVIE_DETAILS: 'VOD_MOVIE_DETAILS',
    TV_PLAYER: 'TV_PLAYER',
    VOD_PLAYER: 'VOD_PLAYER',
    CONTENT_WORLD: 'CONTENT_WORLD',
    CHANNEL_DETAILS: 'CHANNEL_DETAILS',
    PROFILE_MANAGEMENT: 'PROFILE_MANAGEMENT',
    TVOS_TV_TOP_SHELF_CONTENT: 'TVOS_TV_TOP_SHELF_CONTENT'
};

export const PageIntentsWithPlaceHolders = [
    PageIntents.EPG,
    PageIntents.TV_PLAYER,
    PageIntents.VOD_PLAYER,
    PageIntents.VOD_MOVIE_DETAILS,
    PageIntents.TV_MOVIE_DETAILS,
    PageIntents.VOD_SERIES_DETAILS,
    PageIntents.TV_SERIES_DETAILS,
    PageIntents.CHANNEL_DETAILS,
    PageIntents.PROFILE_MANAGEMENT
];
